import { AlgoliaBase64, getCookie } from '../Utils/algoliaUtils';

const ItemComponent = ({ item, addToCartEnabled }) => {
    const staticImageurl = "https://i8.amplience.net/i/wineenthusiast/";
    const suffiximgurl = "_1?$f825$&fmt=auto";
    const action = `${window.algoliaConfigHeadless.recommend.addToCartParams.action}product/${item.objectID}/`;
    let correctFKey = getCookie('form_key');

    if (correctFKey !== "" && window.algoliaConfigHeadless.recommend.addToCartParams.formKey !== correctFKey) {
        window.algoliaConfigHeadless.recommend.addToCartParams.formKey = correctFKey;
    }

    const mageEncodedAction = AlgoliaBase64.mageEncode(action);

    let priceHtml;
    if (item.type_id === 'configurable') {
        priceHtml = <div className="rec-product-price">{item.price?.USD?.default_formated}</div>;
    } else if (item.type_id === 'simple') {
        priceHtml = item.price?.USD?.default_original_formated ? (
            <div className="rec-product-price">
                <span className="current-price">{item.price.USD.default_formated}</span>
                <span className="old-price ml-4 line-through">
                    {item.price.USD.default_original_formated}
                </span>
            </div>
        ) : (
            <div className="rec-product-price">{item.price?.USD?.default_formated}</div>
        );
    }

    return (
        <div className="product-details" key={item.objectID}>
            <a className="recommend-item product-url" href={item.url} data-objectid={item.objectID} data-index={`${window.algoliaConfigHeadless.indexName}_products`}>
                <img src={item.product_image_name ? `${staticImageurl}${item.product_image_name}${suffiximgurl}` : item.image_url} alt={item.name} />
                <p className="product-name">{item.name}</p>
                {priceHtml}
                {item.total_reviews && (
                    <div className="rec-product-reviews">
                        <span className="stars__icon stars__icon--100 ricon-percentage-star--100"></span>
                        <span className="rec-average-score">{item.average_score.toFixed(1)}</span>
                        <div className="rec-total-reviews">{`${item.total_reviews} reviews`}</div>
                    </div>
                )}
                {addToCartEnabled && (
                    <form className="addTocartForm" action={action} method="post" data-role="tocart-form">
                        <input type="hidden" name="form_key" value={window.algoliaConfigHeadless.recommend.addToCartParams.formKey} />
                        <input type="hidden" name="unec" value={mageEncodedAction} />
                        <input type="hidden" name="product" value={item.objectID} />
                        <button type="submit" className="action tocart primary">
                            <span>{window.algoliaConfigHeadless.translations.addToCart}</span>
                        </button>
                    </form>
                )}
            </a>
        </div>
    );
};

export default ItemComponent;
