/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {gql} from '@apollo/client';
import {CART_FIELDS} from "./cartFields.gql";

export const REMOVE_CART_ITEM_MUTATION = gql`
    ${CART_FIELDS}
    mutation RemoveItemFromCart($cartId: String!, $cartItemUid: ID!) {
        removeItemFromCart(
            input: {
                cart_id: $cartId,
                cart_item_uid: $cartItemUid
            }
        )
        {
            cart {
                ...CartFields
            }
        }
    }
`;
