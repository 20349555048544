/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

export const anchorTo = (to = '#anchor-message') => {
    const url = location.href;
    location.href = to;
    history.replaceState(null, null, url);
};
