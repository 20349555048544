/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

/**
 * CartItemConfigurableOptions Component
 */
export const CartItemConfigurableOptions = ({cartItem}) => {
    if (!cartItem.configurable_options) {
        return null;
    }
    return cartItem.configurable_options.map(
        (option) => <CartItemConfigurableOption key={option.configurable_product_option_value_uid}
                                                option={option}/>
    );
};

/**
 * CartItemConfigurableOption Component
 */
const CartItemConfigurableOption = ({option}) => (
    <div className="minicart-item-option">
        <div className="minicart-item-option-label">
            {option.option_label}:
        </div>
        <div className="minicart-item-option-value">
            {option.value_label}
        </div>
    </div>
);
