/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useContext} from 'preact/compat';
import {SessionContext} from "../sessionContext";

export const useSessionContext = () => {
    return useContext(SessionContext);
};
