// algoliaUtils.js

export const AlgoliaBase64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

    encode: function (input) {
        if (typeof window.btoa === "function") {
            return window.btoa(input);
        }
        return this._base64Encode(input);
    },

    decode: function (input) {
        if (typeof window.atob === "function") {
            return window.atob(input);
        }
        return this._base64Decode(input);
    },

    mageEncode: function (input) {
        return this.encode(input).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, ',');
    },

    mageDecode: function (input) {
        return this.decode(input.replace(/\-/g, '+').replace(/_/g, '/').replace(/,/g, '='));
    },

    _base64Encode: function (input) {
        let output = "";
        let i = 0;
        input = this._utf8Encode(input);
        while (i < input.length) {
            let chr1 = input.charCodeAt(i++);
            let chr2 = input.charCodeAt(i++);
            let chr3 = input.charCodeAt(i++);
            let enc1 = chr1 >> 2;
            let enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            let enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            let enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
                this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
        }
        return output;
    },

    _base64Decode: function (input) {
        let output = "";
        let i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            let enc1 = this._keyStr.indexOf(input.charAt(i++));
            let enc2 = this._keyStr.indexOf(input.charAt(i++));
            let enc3 = this._keyStr.indexOf(input.charAt(i++));
            let enc4 = this._keyStr.indexOf(input.charAt(i++));
            let chr1 = (enc1 << 2) | (enc2 >> 4);
            let chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            let chr3 = ((enc3 & 3) << 6) | enc4;
            output += String.fromCharCode(chr1);
            if (enc3 !== 64) output += String.fromCharCode(chr2);
            if (enc4 !== 64) output += String.fromCharCode(chr3);
        }
        return this._utf8Decode(output);
    },

    _utf8Encode: function (string) {
        return unescape(encodeURIComponent(string));
    },

    _utf8Decode: function (utftext) {
        return decodeURIComponent(escape(utftext));
    }
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return "";
};
