/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {useRemoveCartItemMutation} from "../../hooks/useRemoveCartItemMutation";

/**
 * RemoveItemFromCart Component
 *
 * usage: <RemoveItemFromCart cartItem={cartItem} cart={cart} />
 */
export const RemoveItemFromCart = ({cartItem, cart}) => {
    const [RemoveItemFromCart] = useRemoveCartItemMutation();

    function removeItem(e) {
        e.preventDefault();
        RemoveItemFromCart({
            variables: {
                cartId: cart.id,
                cartItemUid: cartItem.uid,
            },
        });
    }

    return (
        <a href="#"
           onClick={removeItem}
           className="minicart-item-remove"
           title={t`Remove ${cartItem.product.name} from the cart`}/>
    )
};
