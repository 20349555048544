/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {gql} from '@apollo/client';
import {CART_FIELDS} from "../../Cart";

export const ADD_PRODUCTS_TO_CART_MUTATION = gql`
    ${CART_FIELDS}
    mutation AddProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
        addProductsToCart(
            cartId: $cartId,
            cartItems: $cartItems
        )
        {
            cart {
                ...CartFields
            }
            user_errors {
                message
            }
        }
    }
`;
