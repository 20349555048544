/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {useSessionContext} from "../../Session";
import {useAddProductsToCartMutation} from "../../Cart";
import {useEffect} from "preact/compat";
import {message, successType, useMessageContext} from "../../Message";
import {useEnvironmentContext} from '../../Environment';
import {anchorTo as anchorToMessages} from '../../Message/hooks/anchor';

/**
 * AddToCart Component
 *
 * usage: <AddToCart product={product} /> (type is ProductInterface)
 */
export const AddToCart = ({product}) => {
    const {cart, loading} = useSessionContext();
    if (!cart || loading || !product) {
        return;
    }

    return <AddToCartLoaded cart={cart} product={product}/>
};

const AddToCartLoaded = ({cart, product}) => {
    const {setMessage} = useMessageContext();
    const {getUrl} = useEnvironmentContext();

    const [AddProductsToCart, {errors, data}] = useAddProductsToCartMutation({
        cartId: cart.id,
        cartItems: [{
            sku: product.sku,
            quantity: 1,
        }]
    });

    useEffect(() => {
        new Promise(resolve => {
            if (errors && errors.length > 0) {
                setMessage(errors);
                resolve();
            }
        }).then(() => {
            anchorToMessages();
        });

        new Promise(resolve => {
            if (data && data.addProductsToCart.user_errors.length === 0) {
                setMessage(message(successType,
                    `You added ${product.name} to your <a href="${getUrl('checkout/cart')}">shopping cart</a>.`,
                    true
                ));
                resolve();
            }
        }).then(() => {
            anchorToMessages();
        })
    }, [errors, data, product, setMessage, getUrl]);

    return (
        <a href="javascript:void(0)" onClick={AddProductsToCart}>
            {t`Add To Cart`}
        </a>
    );
};
