/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useMutation} from "@apollo/client";
import {UPDATE_CART_ITEMS_MUTATION} from "../gql/updateCartItems.gql";

export const useUpdateCartItemsMutation = () => useMutation(UPDATE_CART_ITEMS_MUTATION);
