/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useAddProductsToCompareListMutation} from "../../Compare";
import {useSessionContext} from "../../Session";

/**
 * AddToCompare Component
 *
 * usage: <AddToCompare product={product} /> (type is ProductInterface)
 */
export const AddToCompare = ({product}) => {
    const {loading} = useSessionContext();
    if (loading) {
        return;
    }

    const [AddProductsToCompareList] = useAddProductsToCompareListMutation({
        productIds: [product.id]
    });

    function addProductToCompareList(e) {
        e.preventDefault();
        AddProductsToCompareList();
    }

    return (
        <a href="#" onClick={addProductToCompareList}>Add To Compare</a>
    )
};
