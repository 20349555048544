/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {Wishlist} from "./wishlist";
import {createPortal, useMemo} from "preact/compat";
import {DOM_WISHLIST_SELECTOR} from "./constants";

/**
 * WishlistPortal Component
 *
 * usage: <WishlistPortal />
 */
export const WishlistPortal = ({
                                   selector = DOM_WISHLIST_SELECTOR
                               }) => {
    return useMemo(() => {
        const elements = Array.from(document.querySelectorAll(selector));
        return elements.map((element) => {
            return createPortal(<Wishlist/>, element);
        });
    }, [selector]);
};
