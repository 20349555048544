/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";

/**
 * CartDropdownEmpty Component
 */
export const CartDropdownEmpty = () => (
    <div className="minicart-empty">
        {t`You have no items in your shopping cart.`}
    </div>
);
