/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

export const DOM_PRODUCT_SELECTOR = '.hl-product-card';
export const DOM_PRODUCT_SKU_ATTRIBUTE = 'data-sku';

export const DOM_PRODUCT_LIST_SELECTOR = '.hl-product-list';
export const DOM_PRODUCT_LIST_FILTER_ATTRIBUTE = 'data-filter';

export const DOM_ADD_TO_WISHLIST_SELECTOR = '.hl-product-add-to-wishlist';
