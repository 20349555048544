/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {createContext} from "preact/compat";

export const EnvironmentContext = createContext({
    environmentData: {},
    storeCode: '',
    getUrl() {
    }
});
