/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {createPortal, useMemo} from "preact/compat";
import {DOM_CUSTOMER_SELECTOR} from "../constants";
import {CustomerNavigation} from "./customerNavigation";

/**
 * CustomerNavigationPortal Component
 *
 * usage: <CustomerNavigationPortal />
 */
export const CustomerNavigationPortal = ({
                                             selector = DOM_CUSTOMER_SELECTOR
                                         }) => {
    return useMemo(() => {
        const elements = Array.from(document.querySelectorAll(selector));
        return elements.map((element) => {
            const type = element.getAttribute("data-type") || 'desktop';
            return createPortal(<CustomerNavigation type={type}/>, element);
        });
    }, [selector]);
};
