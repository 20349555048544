/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {LOAD_SESSION_QUERY} from "../gql/session.gql";
import {useQuery} from "@apollo/client";

export const useSessionQuery = () => {
    const result = useQuery(LOAD_SESSION_QUERY);
    result.data = {
        cart: result.data?.passportCart,
        customer: result.data?.customer,
        formKey: result.data?.formKey,
        loading: result.loading
    };
    return result;
};
