/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useSessionContext} from "../../Session";
import {LoggedInCustomerNavigation} from "./LoggedIn";
import {GuestCustomerNavigation} from "./Guest";

/**
 * CustomerNavigation Component
 *
 * usage: <CustomerNavigation />
 */
export const CustomerNavigation = ({type}) => {
    const {customer} = useSessionContext();

    return (
        <div className="customer-navigation">
            {
                customer ?
                    <LoggedInCustomerNavigation type={type}/> :
                    <GuestCustomerNavigation type={type}/>
            }
        </div>
    );
};
