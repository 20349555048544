/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {gql} from '@apollo/client';
import {CART_FIELDS} from "./cartFields.gql";

export const UPDATE_CART_ITEMS_MUTATION = gql`
    ${CART_FIELDS}
    mutation UpdateCartItems($cartId: String!, $cartItems: [CartItemUpdateInput]!) {
        updateCartItems(
            input: {
                cart_id: $cartId,
                cart_items: $cartItems
            }
        )
        {
            cart {
                ...CartFields
            }
        }
    }
`;
