/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {gql} from '@apollo/client';

export const GET_PRODUCTS_QUERY = gql`
    query getProducts($filter: ProductAttributeFilterInput) {
        products(
            filter: $filter
        ) {
            items {
                id
                uid
                sku
                name
                canonical_url
                stock_status
                rating_summary

                thumbnail {
                    url
                    label
                }

                price_range {
                    minimum_price {
                        regular_price {
                            currency
                            value
                        }
                        final_price {
                            currency
                            value
                        }
                    }
                    maximum_price {
                        final_price {
                            currency
                            value
                        }
                    }
                }
            }
        }
    }
`;
