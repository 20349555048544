/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {CachePersistor} from 'apollo-cache-persist';
import {useEffect, useMemo, useState} from 'preact/compat';
import {useEnvironmentContext} from '../../Environment';
import {CACHE_PERSIST_PREFIX} from '../constants';
import {stripIgnoredCharacters} from "graphql";

export const useAdapter = () => {
    const environmentContext = useEnvironmentContext();
    const [initialized, setInitialized] = useState(false);

    const apolloClient = useMemo(() => {
        const {getUrl, storeCode} = environmentContext;
        const httpLink = createHttpLink({
            uri: getUrl('graphql'),
            credentials: 'same-origin',
            headers: {
                store: storeCode
            },
            useGETForQueries: true,
            fetch: (url, options) => {
                const href = new URL(url);
                if (href.searchParams.has('query')) {
                    href.searchParams.set('query', stripIgnoredCharacters(href.searchParams.get('query')));
                }
                return fetch(href, options);
            },
        });

        const cache = new InMemoryCache({
            typePolicies: {
                Cart: {
                    keyFields: ["id"],
                },
                Customer: {
                    keyFields: ["email"],
                },
                CompareList: {
                    keyFields: ["uid"],
                },
                Wishlist: {
                    keyFields: ["id"],
                }
            },
        });

        const client = new ApolloClient({
            connectToDevTools: true,
            link: httpLink,
            cache,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                    nextFetchPolicy: 'cache-first',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
                mutate: {
                    errorPolicy: 'all',
                },
            }
        });

        client.persistor = new CachePersistor({
            key: `${CACHE_PERSIST_PREFIX}_${storeCode}`,
            cache,
            storage: window.localStorage,
            debug: process.env.DEBUG
        });

        return client;
    }, [environmentContext]);

    useEffect(() => {
        if (initialized) {
            return;
        }

        // immediately invoke this async function
        (async () => {
            // restore persisted data to the Apollo cache
            await apolloClient.persistor.restore();

            // mark this routine as complete
            setInitialized(true);
        })();
    }, [initialized, apolloClient]);

    const apolloProps = {client: apolloClient};

    return {
        initialized,
        apolloProps,
    };
};
