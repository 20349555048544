/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";

/**
 * CartItemCustomizableOptions Component
 */
export const CartItemCustomizableOptions = ({cartItem}) => {
    if (!cartItem.customizable_options) {
        return null;
    }
    return cartItem.customizable_options.map(
        (option) => <CartItemCustomizableOptionGroup key={option.customizable_option_uid}
                                                     option={option}/>
    );
};

const CartItemCustomizableOptionGroup = ({option}) => {
    if (!option.values || !option.values.length) {
        return null;
    }
    return (
        <div className="minicart-item-option-group">
            {
                option.values.map(
                    (value) => <CartItemCustomizableOption key={value.customizable_option_value_uid}
                                                           value={value}
                                                           option={option}
                    />
                )
            }
        </div>
    );
};

/**
 * CartItemCustomizableOption Component
 */
const CartItemCustomizableOption = ({value, option}) => {
    let optionValueText = value.value;
    let label = value.label;

    if (!label) {
        label = option.label;
    }

    if (option.type === 'checkbox') {
        optionValueText = parseInt(optionValueText, 10) ? t`Yes` : t`No`;
    }

    return (
        <div className="minicart-item-option">
            <div className="minicart-item-option-label">
                {label}:
            </div>
            <div className="minicart-item-option-value">
                {optionValueText}
            </div>
        </div>
    );
};
