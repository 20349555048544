/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {render} from "preact";
import {Application} from "./components/Application";

const root = document.createElement('div');
render(<Application environmentData={window.headlessContext}/>, root);
