/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {GET_PRODUCTS_QUERY} from "../gql/getProducts.gql";
import {useQuery} from "@apollo/client";

export const useProductsQuery = (options) => {
    const {error, loading, data} = useQuery(GET_PRODUCTS_QUERY, options);

    return {
        error,
        loading,
        products: data?.products?.items
    };
};
