/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {createPortal, useMemo} from "preact/compat";
import {DOM_PRODUCT_SELECTOR, DOM_PRODUCT_SKU_ATTRIBUTE} from "../constants";
import {useDomProductsQuery} from "../hooks/useDomProductsQuery";
import {ProductCard} from "./productCard";

/**
 * ProductCardPortal Component
 */
export const ProductCardPortal = ({
                                      selector = DOM_PRODUCT_SELECTOR,
                                      skuAttribute = DOM_PRODUCT_SKU_ATTRIBUTE
                                  }) => {
    const {products} = useDomProductsQuery();

    return useMemo(() => {
        const elements = Array.from(document.querySelectorAll(selector));
        return elements.map(element => {
            const sku = element.getAttribute(skuAttribute);
            const attrs = {product: products[sku]};
            return createPortal(<ProductCard {...attrs} />, element);
        });
    }, [selector, skuAttribute, products]);
};
