/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {useUpdateCartItemsMutation} from "../hooks/useUpdateCartItemsMutation";
import {useMemo} from "preact/compat";

/**
 * UpdateCartItemsButton Component
 *
 * usage: <UpdateCartItemsButton cart={cart} />
 */
export const UpdateCartItemsButton = ({cart, reducer}) => {
    const [updateCartItems, {error}] = useUpdateCartItemsMutation();
    const [state, dispatch] = reducer;

    const changed = useMemo(() => {
        let changed = false;
        for (let cartItem of cart.items) {
            const qtyInState = state.quantities[cartItem.uid];
            if (qtyInState && qtyInState !== cartItem.quantity) {
                changed = true;
            }
        }
        return changed;
    }, [state.quantities, cart.items]);

    function updateCartItemsClick(e) {
        e.preventDefault();

        const cartItems = [];
        for (let cartItem of cart.items) {
            const qtyInState = state.quantities[cartItem.uid];
            cartItems.push({
                cart_item_uid: cartItem.uid,
                quantity: qtyInState ? qtyInState : cartItem.quantity
            });
        }

        updateCartItems({
            variables: {
                cartId: cart.id,
                cartItems
            }
        }).then(() => {
            dispatch({type: 'resetQuantities'});
        });
    }

    return (
        <div className={`minicart-update-button ${changed ? 'active' : ''}`}>
            {
                error ?
                    <span className="error-message">{error.toString()}</span> :
                    null
            }
            <a href="#" onClick={updateCartItemsClick}>
                {t`Update the Cart`}
            </a>
        </div>
    )
};
