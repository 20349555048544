import {t} from "ttag";
import {useEnvironmentContext} from "../../Environment";
import {CartItem} from "../Item";
import {UpdateCartItemsButton} from "../Update";
import {Price} from "../../Price";
import ShowRelatedProducts from "./showRelatedProducts";
import { render } from 'preact';
import { useEffect } from 'preact/hooks';

/**
 * Renders Algolia recommendation component if enabled
 */
const AlgoliaRecommendation = (objectIds) => {
    const targetElement = document.querySelector('#algolia-recommendation-container');
    if (window.AlgoliaEnabled && window.AlgoliaEnabled.isAlgoliaMiniCartEnabled) {
        if (targetElement) {
            render(<ShowRelatedProducts objectIds={objectIds}/>, targetElement);
        }
    } else {
        console.log("Algolia Mini Cart Recommendation is disabled.");
    }
};

/**
 * CartDropdownHasItems Component
 */
export const CartDropdownHasItems = ({cart, reducer}) => {
    const {getUrl} = useEnvironmentContext();

    // Exclude warranty-product type items when mapping objectIds
    const objectIds = cart.items
        .filter(cartItem => {
            return cartItem.product.__typename !== 'WarrantyProduct';
        })
        .map(cartItem => atob(cartItem.product.uid));

    useEffect(() => {
        AlgoliaRecommendation(objectIds);
    }, [cart]);

    return (
        <div className="minicart-has-items">
            <div className="minicart-cart-link">
                <a href={getUrl('checkout/cart')}>
                    {t`View and Edit Cart`}
                </a>
            </div>
            <div className="minicart-checkout-link">
                <a href={getUrl('checkout')}>
                    {t`Proceed to checkout`}
                </a>
            </div>
            <div className="minicart-items">
                {
                    cart.items.map(
                        (cartItem) => <CartItem
                            cartItem={cartItem}
                            cart={cart}
                            reducer={reducer}
                        />
                    )
                }
            </div>
            {/* Algolia recommendation container */}
            <div id="algolia-recommendation-container"></div>
            <UpdateCartItemsButton cart={cart} reducer={reducer}/>
            <div className="minicart-subtotal">
                <span className="title">
                    {t`Subtotal`}
                </span>
                <span className="value">
                    <Price value={cart.prices.subtotal_excluding_tax}/>
                </span>
            </div>
            <div className="minicart-checkout-link">
                <a href={getUrl('checkout')}>
                    {t`Proceed to checkout`}
                </a>
            </div>
        </div>
    );
};
