/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useProductsQuery} from "../hooks/useProductsQuery";
import {ProductCard} from "../Card";

/**
 * ProductList Component
 *
 * usage: <ProductList filter={filter}/> (type is ProductAttributeFilterInput)
 */
export const ProductList = ({filter}) => {
    if (!filter) {
        return;
    }

    const {error, products} = useProductsQuery({variables: {filter}});

    if (error) {
        console.error('Products Fetch Error', filter);
        return;
    }
    if (!products) {
        return;
    }

    return products.map((product) => <ProductCard product={product} key="product-card"/>);
};
