/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {Cart} from "./cart";
import {createPortal, useMemo} from "preact/compat";
import {DOM_CART_SELECTOR} from "./constants";

/**
 * CartPortal Component
 *
 * usage: <CartPortal selector=".element" />
 */
export const CartPortal = ({
                               selector = DOM_CART_SELECTOR
                           }) => {
    return useMemo(() => {
        const elements = Array.from(document.querySelectorAll(selector));
        return elements.map((element) => {
            return createPortal(<Cart/>, element);
        });
    }, [selector]);
};
