/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {useEnvironmentContext} from "../../../Environment";

/**
 * Component GuestCustomerNavigation
 *
 * usage: <GuestCustomerNavigation />
 */
export const GuestCustomerNavigation = ({type}) => {
    const {getUrl} = useEnvironmentContext();
    return (
        <div className="guest">
            <a href={getUrl('customer/account/login')} className="login-link">{t`Sign In`}</a>
        </div>
    );
};
