/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {PriceRange} from "../PriceRange";
import {useEnvironmentContext} from "../../Environment";
import {AddToCart} from "../AddToCart";
import {AddToWishlist} from "../AddToWishlist";
import {AddToCompare} from "../AddToCompare";

/**
 * ProductCard Component
 *
 * usage: <ProductCard product={product} /> (type is ProductInterface)
 */
export const ProductCard = ({product}) => {
    const {getUrl} = useEnvironmentContext();
    if (!product) {
        return;
    }

    return (
        <div className="product-details">
            <a href={`${getUrl(product.canonical_url)}`}>
                <img src={product.thumbnail.url} alt={product.thumbnail.label} width="200"/>
                <h3>{product.name}</h3>
            </a>
            <p className="sku">SKU: {product.sku}</p>
            <p>
                Price: <PriceRange value={product.price_range}/>
            </p>
            <p>{product.stock_status}</p>

            <div className="product-item-actions">
                <div className="actions-primary">
                    <AddToCart product={product}/>
                </div>
                <div class="actions-secondary">
                    <AddToWishlist product={product}/>
                    <AddToCompare product={product}/>
                    <div class="yotpo bottomLine" data-yotpo-product-id={product.id}/>
                </div>
            </div>
        </div>
    )
};
