/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useContext} from 'preact/compat';
import {MessageContext} from "../messageContext";

export const useMessageContext = () => useContext(MessageContext);

export const message = (type, text, isArray = false) => isArray ? [{type, text}] : {type, text};
