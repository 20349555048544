/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {Price} from "../../Price";

/**
 * PriceRange Component
 *
 * usage: <PriceRange value={priceRange} /> (type is PriceRange)
 */
export const PriceRange = ({value}) => {
    if (!value) {
        return;
    }

    const specialPriceHtml = value.minimum_price.final_price.value < value.minimum_price.regular_price.value ? (
        <div className="special-price">Old price: <s><Price value={value.minimum_price.regular_price}/></s></div>
    ) : null;

    if (value.minimum_price.final_price.value === value.maximum_price.final_price.value) {
        return (
            <span>
                <Price value={value.minimum_price.final_price}/>
                {specialPriceHtml}
            </span>
        )
    }

    return (
        <span className="price-range">
            <Price value={value.minimum_price.final_price}/>
            -
            <Price value={value.maximum_price.final_price}/>
            {specialPriceHtml}
        </span>
    )
};
