/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useEnvironmentContext} from "../../Environment";
import {useSessionContext} from "../../Session";

/**
 * AddToWishlist Component
 *
 * usage: <AddToWishlist product={product} linkText="Add to Wishlist" /> (type is ProductInterface)
 */
export const AddToWishlist = ({product}) => {
    const {getUrl} = useEnvironmentContext();
    const {formKey} = useSessionContext();

    return (
        <form action={getUrl('wishlist/index/add', {product: product.id})}
              method="POST"
              className="add-to-wishlist-form actions-secondary">
            <button type="submit" className="action towishlist">
                Add to Wishlist
            </button>
            <input type="hidden" name="formKey" value={formKey}/>
        </form>
    );
};

export const AddToWishlistCart = ({product, configurableOptions, customizableOptions}) => {
    const {getUrl} = useEnvironmentContext();
    const {formKey} = useSessionContext();

    return (
        <form action={getUrl('wishlist/index/add', {product: product.id})}
              method="POST"
              className="add-to-wishlist-form actions-secondary">
            <button type="submit" className="action towishlist">
                Add to Wishlist
            </button>
            <input type="hidden" name="formKey" value={formKey}/>
            {
                configurableOptions.map(
                    (option) => <WishlistConfigurableOptions option={option}/>
                )
            }
            {
                customizableOptions.map(
                    (option) => <WishlistCustomizableOptions option={option} values={option.values}/>
                )
            }
        </form>
    );
};

const WishlistConfigurableOptions = ({option}) => (
    <input type="hidden" name={`super_attribute[${option.id}]`} value={option.value_id}/>
);

const WishlistCustomizableOptions = ({option, values}) => (
    values.map(
        (value) => <WishlistCustomizableOption value={value} option={option}/>
    )
);

const WishlistCustomizableOption = ({value, option}) => (
    <input type="hidden" name={`options[${option.id}]`} value={value.value}/>
);
