/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {MessageContext} from "./";
import {useState} from 'preact/compat';

/**
 * MessageProvider Component
 *
 * usage: <MessageProvider
 */
const MessageContextProvider = props => {
    const [message, setMessage] = useState("");

    return (
        <MessageContext.Provider value={{message, setMessage}}>
            {props.children}
        </MessageContext.Provider>
    )
};

export const MessageProvider = props => (
    <MessageContextProvider>
        {props.children}
    </MessageContextProvider>
);
