/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {Message} from "./message";
import {createPortal, useMemo} from "preact/compat";
import {DOM_MESSAGES_SELECTOR} from "./constants";

/**
 * MessagePortal Component
 *
 * usage: <MessagePortal />
 */
export const MessagePortal = ({
                                  selector = DOM_MESSAGES_SELECTOR
                              }) => {
    return useMemo(() => {
        return Array.from(document.querySelectorAll(selector))
            .map(element => createPortal(<Message/>, element));
    }, [selector]);
};
