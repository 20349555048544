/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {DOM_ADD_TO_WISHLIST_SELECTOR, DOM_PRODUCT_SKU_ATTRIBUTE} from "../constants";
import {createPortal, useMemo} from "preact/compat";
import {useDomProductsQuery} from "../hooks/useDomProductsQuery";
import {AddToWishlist} from "./addToWishlist";

/**
 * AddToWishlistPortal Component
 *
 * usage: <AddToWishlistPortal selector=".selector" skuAttribute="data-sku" />
 */
export const AddToWishlistPortal = ({
                                        selector = DOM_ADD_TO_WISHLIST_SELECTOR,
                                        skuAttribute = DOM_PRODUCT_SKU_ATTRIBUTE
                                    }) => {
    const {loading, products, version} = useDomProductsQuery();

    return useMemo(() => {
        const elements = Array.from(document.querySelectorAll(selector));
        return elements.map((element) => {
            const sku = element.getAttribute(skuAttribute);
            if (!products[sku]) {
                if (!loading) {
                    console.error(t`SKU ${sku} wasn't found for the wishlist snippet`);
                }
                return null;
            }
            return createPortal(<AddToWishlist product={products[sku]} loading={loading}/>, element);
        });
    }, [selector, loading, version, products]);
};
