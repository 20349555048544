/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {Price} from "../../Price";
import {useEnvironmentContext} from "../../Environment";
import {RemoveItemFromCart} from "./Remove";
import {CartItemQty} from "./Qty";
import {CartItemOptions} from "./Options";

/**
 * CartItem Component
 *
 * usage: <CartItem cartItem={cartItem} /> (type is CartItemInterface)
 */
export const CartItem = ({cartItem, cart, reducer}) => {
    const {getUrl} = useEnvironmentContext();
    const configurableOptions = cartItem.configurable_options ? cartItem.configurable_options : [],
        customizableOptions = cartItem.customizable_options ? cartItem.customizable_options : [];

    return <div className="minicart-item">
        <RemoveItemFromCart cartItem={cartItem} cart={cart} />
        <div className="minicart-item-image">
            {cartItem.product.sku.includes("mulberry") ?
                <p>
                    <img src={cartItem.product.thumbnail.url} alt={cartItem.product.thumbnail.label} width="100" />
                </p>
                :
                <a href={`${getUrl(cartItem.product.canonical_url)}`}>
                    <img src={cartItem.product.thumbnail.url} alt={cartItem.product.thumbnail.label} width="100" />
                </a>
            }
        </div>
        <div className="minicart-item-content">
            <div className="minicart-item-name">
                {cartItem.product.sku.includes("mulberry") ?
                    <p>
                        {cartItem.product.name}
                    </p>
                    :
                    <a href={`${getUrl(cartItem.product.canonical_url)}`}>
                        {cartItem.product.name}
                    </a>
                }
            </div>
            <CartItemOptions cartItem={cartItem} cart={cart} reducer={reducer} />
            <div className="minicart-item-price-qty">
                <CartItemQty cartItem={cartItem} cart={cart} reducer={reducer} />
                <div className="minicart-price-container">
                    <div className="minicart-item-price">
                        <Price value={cartItem.prices.price} />
                    </div>
                    {
                        cartItem.product.__typename === 'ConfigurableProduct' &&
                        cartItem.configured_variant.price_range.minimum_price.final_price.value < cartItem.configured_variant.price_range.minimum_price.regular_price.value &&
                        <div className="minicart-item-original-price">
                            <Price value={cartItem.configured_variant.price_range.minimum_price.regular_price} />
                        </div>
                    }
                    {
                        cartItem.product.__typename === 'SimpleProduct' &&
                        cartItem.product.price_range.minimum_price.final_price.value < cartItem.product.price_range.minimum_price.regular_price.value &&
                        <div className="minicart-item-original-price">
                            <Price value={cartItem.product.price_range.minimum_price.regular_price} />
                        </div>
                    }
                </div>
            </div>
            {/* <div className="minicart-item-actions">
      <AddToWishlistCart product={cartItem.product}
                         configurableOptions={configurableOptions}
                         customizableOptions={customizableOptions}
                         linkText={t`Add to Wish List`}/>
</div> */}
        </div>
    </div>
};
