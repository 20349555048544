/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useReducer} from "preact/compat";

export const useCartReducer = () => {
    return useReducer(cartReducer, cartInitialState);
};

const cartInitialState = {
    showDropdown: false,
    quantities: {}
};

const cartReducer = (state, action) => {
    const newState = Object.assign({}, state);
    newState.quantities = Object.assign({}, state.quantities);

    switch (action.type) {
        case 'showDropdown':
            newState.showDropdown = !!action.value;
            break;
        case 'resetQuantities':
            newState.quantities = {};
            break;
        case 'setQuantity':
            action.value = parseInt(action.value, 10);
            if (isNaN(action.value) || action.value < 1) {
                action.value = 1;
            }
            if (action.value !== action.oldValue) {
                newState.quantities[action.uid] = action.value;
            } else {
                delete newState.quantities[action.uid];
            }
            break;
        default:
            throw new Error();
    }

    if (!newState.showDropdown) {
        return cartInitialState;
    }

    return newState;
};
