/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";

/**
 * CartItemQty Component
 *
 * usage: <CartItemQty cartItem={cartItem} cart={cart} />
 */
export const CartItemQty = ({cartItem, reducer}) => {
    const [state, dispatch] = reducer;
    const quantityValue = state.quantities[cartItem.uid] || cartItem.quantity;

    function updateQuantity(newQuantity) {
        dispatch({
            type: 'setQuantity',
            uid: cartItem.uid,
            value: newQuantity,
            oldValue: cartItem.quantity
        });
    }

    function changeQuantity(delta) {
        updateQuantity(quantityValue + delta);
    }

    return (
        <div className="minicart-item-qty">
            <a href="#"
               className="decrement"
               onClick={e => {
                   e.preventDefault();
                   changeQuantity(-1);
               }}
               title={t`Decrement quantity`}/>
            <input type="number"
                   value={quantityValue}
                   className="minicart-item-qty-input"
                   onInput={e => updateQuantity(e.target.value)}
            />
            <a href="#"
               className="increment"
               onClick={e => {
                   e.preventDefault();
                   changeQuantity(1);
               }}
               title={t`Increment quantity`}/>
        </div>
    );
};
