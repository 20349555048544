/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {SessionContext} from "./sessionContext";
import {useSessionQuery} from "./hooks/useSessionQuery";
import {ApolloConsumer} from '@apollo/client';
import {useEffect} from "preact/compat";

/**
 * SessionProvider Component
 *
 * usage: <SessionProvider />
 */
export const SessionProvider = (props) => {
    return (
        <SessionExternalManipulation>
            <SessionContextProvider>
                {props.children}
            </SessionContextProvider>
        </SessionExternalManipulation>
    )
};

const SessionContextProvider = (props) => {
    const {data} = useSessionQuery();
    return (
        <SessionContext.Provider value={data}>
            {props.children}
        </SessionContext.Provider>
    )
};

const SessionExternalManipulation = (props) => {
    return (
        <ApolloConsumer>
            {client => {
                useEffect(() => {
                    window.headlessReloadSession = async function () {
                        await client.refetchQueries({
                            include: ['Session'],
                        });
                    };
                }, [client]);

                return props.children;
            }}
        </ApolloConsumer>
    )
};
