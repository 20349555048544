/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {useEffect, useState} from "preact/compat";
import {useEnvironmentContext} from "../../../Environment";

/**
 * LoggedInCustomerNavigation Component
 *
 * usage: <LoggedInCustomerNavigation />
 */
export const LoggedInCustomerNavigation = ({type}) => {
    return type === 'mobile' ? <LoggedInCustomerNavigationMobile/> : <LoggedInCustomerNavigationDesktop/>;
};

const LoggedInCustomerNavigationMobile = () => {
    const {getUrl} = useEnvironmentContext();
    return (
        <div className="logged-in">
            <a href={getUrl('customer/account')}>{t`My Account`}</a>
        </div>
    );
};

const LoggedInCustomerNavigationDesktop = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const {getUrl} = useEnvironmentContext();

    function stopPropagation(e) {
        e.stopPropagation();
    }

    function toggleDropdown(e) {
        e.preventDefault();
        setShowDropdown(!showDropdown);
    }

    useEffect(() => {
        document.body.addEventListener("click", () => {
            setShowDropdown(false);
        }, false);
    }, []);

    return (
        <div className="logged-in account-logged-in">
            <a class="account-url" href={getUrl('customer/account')}>{t`My Account`}</a>

            <div class="account-logged-in-dropdown">
                <div className="dropdown">
                    <div><a href={getUrl('customer/account')}>{t`My Account`}</a></div>
                    <div><a href={getUrl('sales/order/history')}>{t`My Orders`}</a></div>
                    <div><a href={getUrl('wesupply/track/shipment')}>{t`Track My Order`}</a></div>
                    <div><a href={getUrl('customer/account/logout')}>{t`Sign Out`}</a></div>
                </div>
            </div>

        </div>
    );
};
