/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {CustomerNavigationPortal} from "./Navigation";

/**
 * CustomerPortals Component
 */
export const CustomerPortals = () => {
    return [
        <CustomerNavigationPortal key="customer-navigation"/>,
    ];
};
