/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {gql} from '@apollo/client';

export const ADD_PRODUCTS_TO_COMPARE_LIST_MUTATION = gql`
    mutation AddProductsToCompareList($compareListUid: ID!, $productIds: [ID]!) {
        addProductsToCompareList(
            input: {
                uid: $compareListUid,
                products: $productIds
            }
        ) {
            items {
                uid
            }
        }
    }
`;

export const CREATE_COMPARE_LIST_MUTATION = gql`
    mutation CreateCompareList($productIds: [ID]) {
        createCompareList(
            input: {
                products: $productIds
            }
        ) {
            uid
        }
    }
`;
