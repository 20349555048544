/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

export const DOM_MESSAGES_SELECTOR = '#hl-messages';
export const {successType, warningType, errorType, noticeType} = {
    successType: 'success', warningType: 'warning', errorType: 'error', noticeType: 'notice'
};
