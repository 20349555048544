/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {gql} from '@apollo/client';

export const CART_FIELDS = gql`
    fragment CustomizableOptionFields on SelectedCustomizableOption {
        id
        customizable_option_uid
        label
        type
        values {
            customizable_option_value_uid
            label
            value
        }
    }
    fragment CartFields on Cart {
        id
        items {
            uid
            prices {
                price {
                    currency
                    value
                }
            }
            product {
                uid
                name
                __typename
                price_range {
                    minimum_price {
                        regular_price {
                            currency
                            value
                        }
                        final_price {
                            currency
                            value
                        }
                    }
                    maximum_price {
                        regular_price {
                            currency
                            value
                        }
                        final_price {
                            currency
                            value
                        }
                    }
                }
                thumbnail {
                    label
                    url
                }
                sku
                canonical_url
            }
            quantity
            ... on ConfigurableCartItem {
                configured_variant {
                    special_price
                    price_range {
                        minimum_price {
                            regular_price {
                                currency
                                value
                            }
                            final_price {
                                currency
                                value
                            }
                        }
                        maximum_price {
                            regular_price {
                                currency
                                value
                            }
                            final_price {
                                currency
                                value
                            }
                        }
                    }
                }
                configurable_options {
                    configurable_product_option_uid
                    value_id
                    option_label
                    value_label
                    configurable_product_option_value_uid
                }
                customizable_options {
                    ... CustomizableOptionFields
                }
            }
            ... on BundleCartItem {
                bundle_options {
                    uid
                    label
                    type
                    values {
                        id
                        label
                        price
                        quantity
                    }
                }
                customizable_options {
                    ... CustomizableOptionFields
                }
            }
            ... on SimpleCartItem {
                customizable_options {
                    ... CustomizableOptionFields
                }
            }
            ... on VirtualCartItem {
                customizable_options {
                    ... CustomizableOptionFields
                }
            }
            ... on WarrantyCartItem {
                customizable_options {
                    ... CustomizableOptionFields
                }
            }
        }
        prices {
            subtotal_excluding_tax {
                currency
                value
            }
        }
        total_quantity
    }
`;
