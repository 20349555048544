/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {REMOVE_CART_ITEM_MUTATION} from "../gql/removeCartItem.gql";
import {useMutation} from "@apollo/client";

export const useRemoveCartItemMutation = () => useMutation(REMOVE_CART_ITEM_MUTATION);
