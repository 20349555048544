import { InstantSearch, RelatedProducts } from 'react-instantsearch';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import HeaderComponent from './Components/HeaderComponent';
import ItemComponent from './Components/ItemComponent';

function ShowRelatedProducts({ objectIds }) {
    if (typeof window.algoliaConfigHeadless === 'undefined') {
        console.log('Algolia is not enabled or configured.');
        return null;
    }
    const appId = algoliaConfigHeadless.applicationId;
    const apiKey = algoliaConfigHeadless.apiKey;
    const defaultIndexName = `${window.algoliaConfigHeadless.indexName}_products`;
    const maxProducts = algoliaConfigHeadless.recommend.limitRelatedProducts;
    const searchClient = algoliasearch(appId, apiKey);
    const transformItems = (items) => {
        return items.slice(0, maxProducts).map((item, index) => ({
            ...item,
            position: index + 1,
        }));
    };

    return (
        <InstantSearch indexName={defaultIndexName} searchClient={searchClient}>
            <RelatedProducts
                objectIDs={objectIds}
                limit={maxProducts}
                transformItems={transformItems}
                headerComponent={HeaderComponent}
                classNames={{
                    container: 'reactAlgoliaSwiper',
                    list: 'swiper-wrapper flex flex-wrap',
                    item: 'swiper-slide w-3/12',
                }}
                itemComponent={({ item }) => (
                    <ItemComponent
                        item={item}
                        addToCartEnabled={window.algoliaConfigHeadless.recommend.isAddToCartEnabledInRelatedProduct}
                    />
                )}
            />
        </InstantSearch>
    );
}

export default ShowRelatedProducts;
