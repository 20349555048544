/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {createPortal, useMemo} from "preact/compat";
import {DOM_PRODUCT_LIST_FILTER_ATTRIBUTE, DOM_PRODUCT_LIST_SELECTOR} from "../constants";
import {ProductList} from "./productList";

/**
 * ProductListPortal Component
 */
export const ProductListPortal = ({
                                      selector = DOM_PRODUCT_LIST_SELECTOR,
                                      filterAttribute = DOM_PRODUCT_LIST_FILTER_ATTRIBUTE
                                  }) => {
    return useMemo(() => {
        const elements = Array.from(document.querySelectorAll(selector));
        return elements.map((element) => {
            const filter = JSON.parse(element.getAttribute(filterAttribute));
            return createPortal(<ProductList filter={filter}/>, element);
        });
    }, [selector, filterAttribute]);
};
