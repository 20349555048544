/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

/**
 * CartItemBundleOptions Component
 */
export const CartItemBundleOptions = ({cartItem}) => {
    if (!cartItem.bundle_options) {
        return null;
    }
    return cartItem.bundle_options.map(
        (option) => <CartItemBundleOption key={option.uid}
                                          option={option}/>
    );
};

/**
 * CartItemBundleOption Component
 */
const CartItemBundleOption = ({option}) => (
    <div className="minicart-item-option">
        <div className="minicart-item-option-value">
            {option.values.map((value) => <div key={value.id}>{`${value.quantity} X ${value.label}`}</div>)}
        </div>
    </div>
);
