/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useEffect} from "preact/compat";

/**
 * Utm Component
 *
 * usage: <Utm>...</Utm>
 */
export const Utm = () => {
    useEffect(() => {
        const href = new URL(window.location.href);
        const params = href.searchParams;

        if (params.get('leadsource')) {
            document.cookie = `leadsource=${params.get('leadsource')}; path=/w; max-age=${60 * 60 * 24 * 14}`;
        }
        if (params.get('email')) {
            document.cookie = `wp_ga4_customer_email=${params.get('email')}; path=/w; max-age=${60 * 60 * 24 * 30}`;
        }
        if (params.get('cjevent')) {
            document.cookie = `wp_ga4_event_id=${params.get('cjevent')}; path=/w; max-age=${60 * 60 * 24 * 30}`;
        }
    }, []);
};
