/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {CartDropdownEmpty} from "./cartDropdownEmpty";
import {CartDropdownHasItems} from "./cartDropdownHasItems";

/**
 * CartDropdown Component
 *
 * usage: <CartDropdown cart={cart} />
 */
export const CartDropdown = ({cart, reducer}) => {
    const [, dispatch] = reducer;

    function stopPropagation(e) {
        e.stopPropagation();
    }

    function closeDropdown(e) {
        e.preventDefault();
        dispatch({type: 'showDropdown', value: false});
    }

    return (
        <div className="minicart-dropdown-background" onClick={closeDropdown}>
            <div className="minicart-dropdown" onClick={stopPropagation}>
                <a href="#" className="minicart-close" onClick={closeDropdown}/>
                <div className="minicart-title">{t`My Cart`}</div>
                {
                    cart.items && cart.items.length > 0 ?
                        <CartDropdownHasItems cart={cart} reducer={reducer}/> :
                        <CartDropdownEmpty cart={cart} reducer={reducer}/>
                }
            </div>
        </div>
    );
};
