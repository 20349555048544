/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {CartItemConfigurableOptions} from "./cartItemConfigurableOptions";
import {CartItemBundleOptions} from "./cartItemBundleOptions";
import {CartItemCustomizableOptions} from "./cartItemCustomizableOptions";

/**
 * CartItemOptions Component
 *
 * usage: <CartItemOptions cartItem={cartItem} cart={cart} />
 */
export const CartItemOptions = ({cartItem}) => (
    <div className="minicart-item-options">
        <CartItemConfigurableOptions cartItem={cartItem}/>
        <CartItemBundleOptions cartItem={cartItem}/>
        <CartItemCustomizableOptions cartItem={cartItem}/>
    </div>
);
