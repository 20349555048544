/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

/**
 * Price Component
 *
 * usage: <Price value={price} /> (type is Money)
 */
export const Price = ({value}) => {
    if (!value) {
        return;
    }

    const price = value.value || 0;
    const priceFormatted = value.currency === 'USD' ?
        `$ ${price.toFixed(2)}` :
        `${price.toFixed(2)} ${value.currency};`;

    return (
        <span className="price">
            {priceFormatted}
        </span>
    )
};
