/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {ProductCardPortal} from "./Card";
import {AddToWishlistPortal} from "./AddToWishlist";
import {ProductListPortal} from "./List";

/**
 * ProductPortals Component
 */
export const ProductPortals = () => [
    <ProductCardPortal key="product-card"/>,
    <ProductListPortal key="product-list"/>,
    <AddToWishlistPortal key="add-to-wishlist"/>,
];
