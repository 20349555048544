// Cart.js
import {t} from 'ttag';
import {useSessionContext} from '../Session';
import {useEffect} from 'preact/compat';
import {CartDropdown} from './Dropdown';
import {useCartReducer} from './hooks/useCartReducer';
import {useAddToCartEffect} from './useAddToCartEffect'; // Add this import

/**
 * Cart Component
 *
 * usage: <Cart />
 */
export const Cart = () => {
    const {cart} = useSessionContext();
    const reducer = useCartReducer();
    const [state, dispatch] = reducer;

    useEffect(() => {
        const bodyClassList = document.body.classList;
        if (state.showDropdown) {
            bodyClassList.add('minicart-open');
        } else {
            bodyClassList.remove('minicart-open');
        }
    }, [state.showDropdown]);

    // Add this hook
    useAddToCartEffect(() => {
        dispatch({type: 'showDropdown', value: true});
    });

    return (
        <div className="minicart">
            <a
                href="#"
                className="minicart-open-link"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch({type: 'showDropdown', value: true});
                }}
            >
                <span className="minicart-open-link-title">{t`Cart`}</span>
                {cart && cart.total_quantity > 0 ? <span className="qty">{cart.total_quantity}</span> : null}
            </a>
            {state.showDropdown && cart ? <CartDropdown cart={cart} reducer={reducer}/> : null}
        </div>
    );
};
