/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useEffect, useState} from "preact/compat";

/**
 * DomReady Component
 *
 * usage: <DomReady>...</DomReady>
 */
export const DomReady = ({children}) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (ready) {
            return;
        }

        if (/complete|interactive|loaded/.test(document.readyState)) {
            setReady(true);
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                setReady(true);
            }, false);
        }
    }, [ready]);

    return ready ? children : null;
};
