// useAddToCartEffect.js
import {useContext, useEffect, useRef} from 'preact/compat';
import {SessionContext} from '../Session';

const isOnCartPage = () => {
    return window.location.pathname === '/cart'; // Replace '/cart' with your cart page path if it's different
};

export const useAddToCartEffect = (onAddToCart) => {
    const {cart} = useContext(SessionContext);
    const prevCartQtyRef = useRef();

    useEffect(() => {
        if (
            prevCartQtyRef.current !== undefined &&
            cart &&
            cart.total_quantity > prevCartQtyRef.current &&
            !isOnCartPage()
        ) {
            onAddToCart();
        }
        prevCartQtyRef.current = cart ? cart.total_quantity : 0;
    }, [cart, onAddToCart]);
};
