/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useEnvironmentContext} from "../../Environment";

export const useLoginUrl = (returnUrl = null) => {
    const {getUrl} = useEnvironmentContext();
    const params = {};

    if (returnUrl) {
        params.referer = encodeReferer(returnUrl);
    }

    return getUrl('customer/account/login', params);
};

const encodeReferer = (referer) => {
    // Implementing \Magento\Framework\Url\Encoder::encode() functionality
    return btoa(unescape(encodeURIComponent(referer)))
        .replaceAll('+', '-')
        .replaceAll('/', '_')
        .replaceAll('=', ',');
};
