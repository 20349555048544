/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {ADD_PRODUCTS_TO_COMPARE_LIST_MUTATION, CREATE_COMPARE_LIST_MUTATION} from "../gql/addProductsToCompareList.gql";
import {useMutation} from "@apollo/client";
import {useSessionContext} from "../../Session";

export const useAddProductsToCompareListMutation = ({productIds}) => {
    const {customer} = useSessionContext();
    const mutationAddProductsToCompareList = useMutation(ADD_PRODUCTS_TO_COMPARE_LIST_MUTATION, {
        variables: {
            compareListUid: customer?.compare_list?.uid,
            productIds
        },
        refetchQueries: [
            'Session'
        ]
    });
    const mutationCreateCompareList = useMutation(CREATE_COMPARE_LIST_MUTATION, {
        variables: {
            productIds
        },
        refetchQueries: [
            'Session'
        ]
    });

    return (customer && customer.compare_list && customer.compare_list.uid) ?
        mutationAddProductsToCompareList :
        mutationCreateCompareList;
};
