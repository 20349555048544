/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {t} from "ttag";
import {useEnvironmentContext} from "../Environment";

/**
 * Wishlist Component
 *
 * usage: <Wishlist />
 */
export const Wishlist = () => {
    const {getUrl} = useEnvironmentContext();

    return <div className="wishlist">
        <a href={getUrl('wishlist')}>
            {t`Wishlist`}
        </a>
    </div>
};
