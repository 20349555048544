/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {EnvironmentContext} from "./environmentContext";

/**
 * EnvironmentProvider Component
 *
 * usage: <EnvironmentProvider environmentData={environmentData} />
 */
export const EnvironmentProvider = ({environmentData, children}) => {
    const getUrl = (path, params) => {
        const pathParts = path.split('/').filter(Boolean);
        if (params) {
            for (let key in params) {
                pathParts.push(key);
                pathParts.push(params[key]);
            }
        }
        path = pathParts.join('/');
        if (pathParts.length > 0) {
            path += '/';
        }

        return `${environmentData.shopUrl}${path}`;
    };

    return (
        <EnvironmentContext.Provider
            value={{
                environmentData,
                storeCode: environmentData.storeCode,
                getUrl
            }}
        >
            {children}
        </EnvironmentContext.Provider>
    )
};
