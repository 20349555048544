/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */
import {useMessageContext} from "../Message";
import {t} from "ttag";
import html from 'html-react-parser';

/**
 * Messages Component
 *
 * usage: <Message />
 * avaliable types: success, warning, error, notice (Message/constants.js)
 */
export const Message = () => {
    const {message} = useMessageContext();
    if (!message) {
        return;
    }

    return (
        <div className="messages" aria-atomic="true" role="alert" id="anchor-message">
            {message.map(({text, type}) =>
                <div
                    key="message"
                    className={`message-${type} ${type} message`}
                    data-ui-id={`message-${type}`}>
                    <div className="icon content">
                        {html(t`${text}`)}
                    </div>
                </div>
            )}
        </div>
    )
};
