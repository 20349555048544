/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

/**
 * Adapter Component
 */
import {ApolloProvider} from '@apollo/client';
import {useAdapter} from './hooks/useAdapter';

export const Adapter = props => {
    const {initialized, apolloProps} = useAdapter(props);

    if (!initialized) {
        return;
    }

    return (
        <ApolloProvider {...apolloProps}>
            {props.children}
        </ApolloProvider>
    );
};
