/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {CustomerPortals} from "../Customer";
import {CartPortal} from "../Cart";
import {WishlistPortal} from "../Wishlist";
import {Adapter} from "../Adapter";
import {EnvironmentProvider} from "../Environment";
import {SessionProvider} from "../Session";
import {DomReady} from "../DomReady";
import {ProductPortals} from "../Product";
import {MessagePortal, MessageProvider} from '../Message';
import {Utm} from "../Utm";

/**
 * Application Component
 */
export const Application = props => {
    if (!props.environmentData) {
        return;
    }

    return (
        <EnvironmentProvider environmentData={props.environmentData}>
            <Adapter>
                <MessageProvider>
                    <DomReady>
                        <SessionProvider>
                            <MessagePortal/>
                            <CustomerPortals/>
                            <CartPortal/>
                            <WishlistPortal/>
                            <ProductPortals/>
                        </SessionProvider>
                        <Utm/>
                    </DomReady>
                </MessageProvider>
            </Adapter>
        </EnvironmentProvider>
    )
};
