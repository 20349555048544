/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {useEffect, useMemo, useState} from "preact/compat";
import {DOM_PRODUCT_SKU_ATTRIBUTE} from "../constants";
import {useProductsQuery} from "./useProductsQuery";

/**
 * Collect product SKUs in DOM and load the products
 */
export const useDomProductsQuery = () => {
    const [version, setVersion] = useState(0);

    useEffect(() => {
        window.headlessReloadProducts = function () {
            setVersion(version + 1);
        };
    }, [version]);

    const skus = useMemo(() => {
        const elements = Array.from(document.querySelectorAll(`div[${DOM_PRODUCT_SKU_ATTRIBUTE}]`));
        const skus = elements.map((element) => element.getAttribute(DOM_PRODUCT_SKU_ATTRIBUTE));
        return [...new Set(skus)];
    }, []);

    const result = useProductsQuery({
        variables: {
            filter: {
                sku: {
                    in: skus
                }
            }
        },
        skip: skus.length === 0
    });

    return useMemo(() => {
        const {error, loading} = result;
        const products = {};
        if (result.products) {
            result.products.map((product) => {
                products[product.sku] = product;
            });
        }
        return {error, loading, products, version};
    }, [result, version]);
};
