/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {ADD_PRODUCTS_TO_CART_MUTATION} from "../gql/addProductsToCart.gql";
import {useMutation} from "@apollo/client";
import {useMemo} from "preact/compat";
import {errorType, message} from "../../Message/";

const errorMessage = 'Couldn\'t add the product to the cart. Please check your internet connection and try again.';

export const useAddProductsToCartMutation = ({cartId, cartItems}) => {
    const [callback, {loading, data}] = useMutation(ADD_PRODUCTS_TO_CART_MUTATION, {
        variables: {
            cartId,
            cartItems
        },
    });

    return useMemo(() => {
        const errorHandler = () => {
            const errors = data?.addProductsToCart?.user_errors ? data?.addProductsToCart?.user_errors : null;
            if (!data || loading) {
                return [];
            }

            if (errors.length > 0) {
                return errors.map(error => message(errorType, error.message));
            }

            return message(errorType, errorMessage, true);
        };

        const errors = errorHandler();

        return [callback, {loading, errors, data}];
    }, [callback, data, loading]);
};
