/**
 * @package     BlueAcorn/Headless
 * @author      Blue Acorn iCi <code@blueacorn.com>
 * @copyright   Copyright © Blue Acorn iCi. All Rights Reserved.
 */

import {gql} from '@apollo/client';
import {CART_FIELDS} from "../../Cart";

export const LOAD_SESSION_QUERY = gql`
    ${CART_FIELDS}
    query Session {
        passportCart {
            ...CartFields
        }
        customer {
            email
            firstname
            lastname
        }
        formKey
    }
`;
